import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import validator from "validator";
import validationService from "./validationService";
import { guestBlogElements as formElements } from "./formElements";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select from "@material-ui/core/Select";
import Checkbox from "@material-ui/core/Checkbox";
import { Box, Button } from "@material-ui/core";
import ParagraphTypography from "../v2/Typography/ParagraphTypography";

import powerBy from "../../images/v2/campaigns/power-by.svg";
import "./form.scss";

export const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
};

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectSpcing: {
    marginBottom: "8px",
  },
}));

const GuestBlogForm = (props) => {
  const [state, setState] = useState({
    data: {
      privacy: false,
      blogCategory: "",
    },
    error: {},
  });

  const handleCheckBox = () => {
    const { data } = state;
    setState((prevState) => {
      return { ...prevState, data: { ...data, privacy: !data.privacy } };
    });
  };

  const handleChange = (e) => {
    const { name, value } = e;
    const { data } = state;
    setState((prevState) => {
      return { ...prevState, data: { ...data, [name]: value } };
    });
  };

  const validate = () => {
    let isValid = true;
    let { error, data } = state;
    formElements.forEach((ele) => {
      let sanitizedInput =
        typeof data[ele.field] === "string"
          ? validator.trim(data[ele.field] || "")
          : data[ele.field];
      const validation = validationService(ele, sanitizedInput);

      error = { ...error, [ele.field]: validation.msg };

      data = { ...data, [ele.field]: sanitizedInput };

      if (validation.msg) {
        isValid = false;
      }
    });

    setState({ data, error });

    return isValid;
  };

  const handleSubmit = () => {
    const isValid = validate();
    if (isValid) {
      try {
        // eslint-disable-next-line no-unused-vars
        fetch("/.netlify/functions/contact-us", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            type: "GuestBlog",
            ...state.data,
          }),
        }).then(() => {
          if (typeof window.gtag === "function")
            window.gtag("event", "conversion", {
              send_to: "AW-615460128/F9KUCL_Oxb0CEKDavKUC",
            });
          // eslint-disable-next-line react/prop-types
          props.handleSuccess();
        });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      }
    }
  };

  const classes = useStyles();
  const options = [
    {
      value: "placeholder",
      label: "Choose a category",
    },
    {
      value: "salesManagement",
      label: "Sales Management",
    },
    {
      value: "customerRelationship",
      label: "Customer Relationship",
    },
    {
      value: "automation",
      label: "Automation",
    },
    {
      value: "businessCommunication",
      label: "Business Communication",
    },
  ];

  return (
    <Box className="pc-google-ad-content-form guest-blog-form" p={2}>
      <form autoComplete="off" noValidate>
        {[
          {
            key: "fullName",
            label: "Name",
            placeholder: "Enter",
            required: true,
          },
          {
            key: "email",
            label: "Email ID",
            placeholder: "Enter",
            required: true,
          },
          {
            key: "linkedInProfile",
            label: "LinkedIn Profile",
            placeholder: "Share profile link",
            required: true,
          },
          {
            key: "workSamples",
            label: "Work Samples",
            placeholder: "Provide minimum 3 published blog links",
            required: true,
          },
          {
            key: "blogCategory",
            label: "Blog Category",
            placeholder: "Choose a category",
            required: true,
          },
          {
            key: "blogTopics",
            label: "Blog Topics",
            placeholder: "Suggest minimum 3 blog topics",
            required: true,
          },
        ].map(({ key, ...props }) => {
          if (key === "blogCategory") {
            return (
              <div className={classes.selectSpcing}>
                <p>{`${props.label}*`}</p>
                <FormControl
                  className={classes.formSelect}
                  fullWidth
                  variant="outlined"
                  error={Boolean(state.error[key] || "")}
                >
                  <Select
                    value={state.data[key]}
                    className={classes.formSelectInput}
                    onChange={(e) =>
                      handleChange({
                        name: "blogCategory",
                        value: e.target.value,
                      })
                    }
                    displayEmpty
                    className={classes.selectEmpty}
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {options.map((option) => {
                      if (option.value === "placeholder") {
                        return (
                          <MenuItem value="" disabled>
                            {option.label}
                          </MenuItem>
                        );
                      }
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {Boolean(state.error[key] || "") && (
                    <FormHelperText id="component-error-text">
                      {state.error[key] || ""}
                    </FormHelperText>
                  )}
                </FormControl>
              </div>
            );
          }

          if (key === "workSamples") {
            return (
              <React.Fragment>
                <p>{`${props.label}*`}</p>
                <TextField
                  error={Boolean(state.error[key] || "")}
                  fullWidth
                  multiline
                  rows={2}
                  helperText={state.error[key] || ""}
                  id="outlined-multiline-static"
                  key={key}
                  margin="normal"
                  name={key}
                  placeholder={props.placeholder}
                  onChange={(e) =>
                    handleChange({ name: key, value: e.target.value })
                  }
                  size="small"
                  value={state.data[key] || ""}
                  variant="outlined"
                />
              </React.Fragment>
            );
          }
          return (
            <React.Fragment>
              <p>{`${props.label}*`}</p>
              <TextField
                error={Boolean(state.error[key] || "")}
                fullWidth
                helperText={state.error[key] || ""}
                id="outlined-required"
                key={key}
                margin="normal"
                name={key}
                placeholder={props.placeholder}
                onChange={(e) =>
                  handleChange({ name: key, value: e.target.value })
                }
                size="small"
                value={state.data[key] || ""}
                variant="outlined"
              />
            </React.Fragment>
          );
        })}

        <Box textAlign="left">
          <FormControlLabel
            className="agree-text"
            control={
              <Checkbox
                checked={state.data.privacy}
                name="checkbox"
                onChange={handleCheckBox}
              />
            }
            label={
              <ParagraphTypography
                color="#343434"
                fontSize={14}
                overrideClassName
              >
                I confirm and agree to the storing and processing of my personal
                details by Pepper Cloud.
              </ParagraphTypography>
            }
          />
        </Box>
        <Box my={2} textAlign="center">
          <Button
            color="secondary"
            fullWidth
            onClick={handleSubmit}
            size="large"
            variant="contained"
          >
            Submit
          </Button>
        </Box>
      </form>
      <Box className="powered-by-peppercloud">
        <Box component={"img"} src={powerBy} />
      </Box>
    </Box>
  );
};

export default GuestBlogForm;
